import React, { Component } from "react";
import Lightbox from "react-image-lightbox";
import Img from "gatsby-image";
import styled from "styled-components";

const GalleryWrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;

  & > div {
    flex: 1;
    cursor: pointer;
    margin: 10px;
    width: 100%;
  }
`;

export default class LightboxGallery extends Component {
  state = {
    photoIndex: 0,
    isOpen: false
  };

  render() {
    const { photoIndex, isOpen } = this.state;
    const { images, alts } = this.props;

    return (
      <GalleryWrapper style={{ ...this.props.style }}>
        {images.map(
          (image, index) =>
            image && (
              <div
                key={image.childImageSharp.fluid.src}
                onClick={() =>
                  this.setState({ isOpen: true, photoIndex: index })
                }
              >
                <Img
                  fluid={image.childImageSharp.fluid}
                  alt={alts && alts[index] ? alts[index] : ""}
                />
              </div>
            )
        )}

        {isOpen && (
          <Lightbox
            imagePadding={50}
            mainSrc={images[photoIndex].childImageSharp.fluid.src}
            nextSrc={
              images[(photoIndex + 1) % images.length].childImageSharp.fluid.src
            }
            prevSrc={
              images[(photoIndex + images.length - 1) % images.length]
                .childImageSharp.fluid.src
            }
            onCloseRequest={() => this.setState({ isOpen: false })}
            onMovePrevRequest={() =>
              this.setState({
                photoIndex: (photoIndex + images.length - 1) % images.length
              })
            }
            onMoveNextRequest={() =>
              this.setState({
                photoIndex: (photoIndex + 1) % images.length
              })
            }
          />
        )}
      </GalleryWrapper>
    );
  }
}
